     .table-contaier{width: auto;
            height: 47vh;
            overflow-y: scroll;
            overflow-x:hidden;
       }
       .counter-text{ color: #B06A0A;
           font-size: 'Open Sans', sans-serif;}
           .table th { color: #820024;}
           
           .counter-btttn{
                background-color: #820024;
                height: 30px;
                border-radius: 4px;
                width:170px;
           }
           .bgclr{
            
               background-color: #F6F6F6;
               height:auto;
               min-height: calc(100vh - 239px);
               overflow-y: hidden !important;
               overflow-x: hidden !important;
           }
            /* @media (max-width: 2560px) {
                .bgclr{
                    background-color: #F6F6F6;
                    height:auto;

                    min-height: calc(100vh - 180px);
                }
                .table-contaier{width: auto;
                    height: 60vh;
                    overflow-y: scroll;
                    overflow-x:hidden;
               }
             } */
            @media (max-width:992px) {
              .bgclr{
                    background-color: #F6F6F6;
                    height:auto;
                    min-height: calc(100vh - 180px);
                }
                .table-contaier{width: auto;
                    height: 60vh;
                    overflow-y: scroll;
                    overflow-x:auto;
               }
             }
   
             th, td {
                padding: 10px;
                text-align: left;
                border-bottom: 1px solid #ddd;
              }
             @media (max-width: 320px) {
                .newT{
                    /* background-color: #d82020 !important; */
                    height:auto;
                  display: block;
                    min-height: calc(100vh - 180px);
                }
                /* .table-contaier{width: auto;
                    height: 60vh;
                    overflow-y: scroll;
                    overflow-x:hidden;
                    background-color: #820024;
               } */
             }
           
:root {
    /* --body-bg: #f5f8ff; */
    --box-bg: #fff;
    --input-bg: #f5f8ff;
    --txt-color: #2f2d2f;
    --txt-second-color: #ccc;
    --border-color: #4267b2;
    --box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.body {
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    line-height: 1.5;
    background-color: var(--body-bg);
    color: var(--txt-color);

    display: flex;
    justify-content: center;
    /* padding-top: 100px; */

}

.box {
    background-color: var(--box-bg);
    padding: 30px;
    border-radius: 20px;
    box-shadow: var(--box-shadow);
}

.header {
    margin-bottom: 30px;
    text-align: center;
}
/* .roundBtn{
    background-color: white;
    color: #720c20;
    border: 2px solid black;
}
.roundBtn:hover{
    background-color: antiquewhite;

} */
.spot{
    text-align: center;
    margin-top: 16px;
    
    color: gray;
    font-weight: bold;
    display: block
}
.MainHeaderLogo {
    color: black;
    text-decoration: solid;
    padding-top: 2px;
}

.MainHeaderLogo img {
    width: 60%;
    max-width: 250px;
}


.plannertext :hover {
    color: black;
    text-decoration: underline;
    text-decoration-color: #D79B48;
}

.plannertext :focus {
    color: black;
    text-decoration: underline;
    text-decoration-color: #D79B48;
}

.plannertext :active {
    color: #0000;
    text-decoration: underline;
    text-decoration-color: #D79B48;
}
/* @media(max-width:1300px){
    .plannertext{ 
       width: 20px;
       font-size: 80%;
    }
   
   } */
/* @media(max-width:1130px){
    .plannertext{ 
       width: 20px;
       font-size: 74%;
    }
   
   } */
/* @media(max-width:1024px){
    .plannertext{ 
       width: 20px;
       font-size: 72%;
    }
   
   } */

.Vertical {
    border-left: 1px solid rgb(205, 204, 204);
    height: 74px;
    float: right;
    padding: 0;
}
.horizontal {
    border-bottom: 1px solid rgb(205, 204, 204);
    height: 74px;
    float: bottom;
    padding: 0;
}

.logoutCircle img {
    width: 30px;
    height: 30px;
}
.sticky-top {
    position: sticky;
    top: 0;
    z-index: 100 !important;
}

/*sumit*/
.navbar-toggler {
    position: relative;
    width: 30px;
    height: 30px;
    padding: 30px;
    transition: .5s ease-in-out;
}

.navbar-toggler ,
.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
    outline: none;
    box-shadow: none;
    border: 0;
}

.navbar-toggler span{
    margin: 0;
    padding: 0;
}

.toggler-icon{
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    background: #777777;
    border-radius: 1px;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: .25s ease-in-out;
}

.middle-bar{
    margin-top: 0px;
    
}

.navbar-toggler .top-bar{
    margin-top: 0px;
    transform: rotate(135deg);

}
.navbar-toggler .middle-bar{
    opacity: 0;
    filter: alpha(opacity=0);
}
.navbar-toggler:focus{
    text-decoration-color: none !important;
    text-decoration-thickness: none !important;
}
.navbar-toggler .bottom-bar{
   margin-top: 0px;
   transform: rotate(-135deg);
}

.navbar-toggler.collapsed .top-bar{
    margin-top: -20px;
    transform: rotate(0deg);
    
}
.navbar-toggler.collapsed .middle-bar{
    opacity: 1;
    filter: alpha(opacity=100);
}
.navbar-toggler.collapsed .bottom-bar{
    margin-top: 20px;
    transform: rotate(0deg);
}





.head {

    position: -webkit-sticky;
    position: -moz-sticky;
    position: -ms-sticky;
}

@media screen and(max-width:600px) {
    
   
}

@media(max-width:1300px){
    .plannertext{ 
       /* width: 20px; */
       font-size: 80%;
    }
   
   }
@media(max-width:1130px){
    .plannertext{ 
       /* width: 20px; */
       font-size: 72.2%;
    }
   
   }
@media(max-width:1024px){
    .plannertext{ 
       /* width: 20px; */
       font-size: 72%;
       padding-left: 0px !important;
    }
   
   }

   @media (min-width: 992px){

       .navbar-expand-lg .navbar-nav .nav-link {
           padding-right: var(--bs-navbar-nav-link-padding-x);
           padding-left: 4px !important;
        }
    }
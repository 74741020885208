.dropdown {
    display: inline-block;
    position: relative;
    
  }
  .dropdown-content {
    display: none;
    position: absolute;
    width: 100%;
    overflow: auto;
    box-shadow: 0px 10px 10px 0px rgba(0,0,0,0.4);
  }
  .dropdown:hover .dropdown-content {
    display: block;
    background-color: #f7eeee;
  }
  .dropdown-content a {
    display: block;
    color: #000000;
    padding: 5px;
    text-decoration: none;
  }
  .dropdown-content a:hover {
    color: #242020;
    background-color: #00A4BD;
  }

@media (max-width:600px){
    .ClassReg-Tag-h4{
       font-size: 17.5px;
    }
}
@media (max-width:350px){
    .ClassReg-Tag-h4{
       font-size: 14px;
    }
}

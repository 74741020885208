.ClassReg-Tag-h4{
    font-weight: 400;;
}
.Search-Div {
    border: 1px solid rgb(156, 152, 152);
    margin-top: 5px;
    width: 320px;
    /* margin-right: 10px; */
}
.search-icon-div{
    width: 40px;
    background-color: #EFEFEF;
}
.search-icon{
    width: 25px;
    height: 25px;
    cursor: pointer;
}

.Card-Style{
    min-width: 20rem;
    max-width: 20rem;
    width: 20rem;
    height: 560px;
}
.profTopic-Tag-H6{
    color: #B06A0A;
    font-size: 22px;
    
}
.profTopic-Tag-span{
    font-weight: 500;
    color: black;
    font-size: 14px;
}
.by-tag-h6{
    color: #707070;
    font-weight: 400;
    font-size: 14px;

    
}
.profName-tag-span{
    color: #720C20;
    font-weight: 500;
}
.common-tag-h6{
    color: #595959;
    font-weight: 400;
     font-size: 14px;
}
.BookNow-Button{
    border:1px solid #720C20;
    color: #720C20;   
}
.Card-Col{
    /* overflow: hidden; */
    scroll-behavior: smooth !important;
    overflow-y: hidden !important;
    /* border: 1px solid rgb(180, 176, 176); */
}


.vertical-scroll::-webkit-scrollbar{
    width: 10px;
    height: 10px;
   
    
}
.vertical-scroll::-webkit-scrollbar-thumb{

    background: rgb(227, 232, 227);
    border-radius: 100vw;
}
.vertical-scroll::-webkit-scrollbar-track{
    background: rgb(89, 91, 89);
    border-radius: 100vw;
    margin-block:10px;

}

@media (max-width:600px)
{
    .profTopic-Tag-H6{
        font-size: 19px;
    }
    .Search-Div{
        width: 278px; 
        margin-left: 2px !important;
    }
    .Card-Style{
        width: 17.5rem !important;
        max-width: 17.5rem;
        min-width: 17.5rem;
        height: 445px;
    }
    
}

 .SampleData1{
   --bs-modal-width : 40.5rem;
   /* margin-top: 2%; */
   
}
@media (max-width: 600px) {
    .new{
        width: 100% !important;
     }
     .preview{
        height: 100%;
        width: 100%;

     }
    
 }
 
 .name{
    align-items: center !important;
   
    
 }
 .new{
    width: 20%;

 }
 .vertical-scroll::-webkit-scrollbar{
    width: 10px;
    height: 10px;
   
    
}
.vertical-scroll::-webkit-scrollbar-thumb{
   background: rgb(89, 91, 89);
    border-radius: 100vw;
}
.vertical-scroll::-webkit-scrollbar-track{
    background: rgb(89, 91, 89);
    background: rgb(227, 232, 227);
    border-radius: 100vw;
    margin-block:10px;

}
 